@use "../3-helpers/constants" as c;

html {
    font-size: 1.4vw;

    & > body {
        background-color: c.$charcoal;
    }
}

.logo {
    width: 25%;
}

.background-image {
    background-size: cover;
    background-repeat: no-repeat;
    height: 130vh;
}

.privacy-policy-link {
    color: black;
    text-decoration: underline;

    &:hover {
        color: c.$coral;
        text-decoration-color: black;
    }
}

ul.montserrat-bullets {
    padding-left: 0.7rem;
}

.commission-table {
    margin: 10px;

    td,
    th {
        border: 1px solid black;
    }

    table {
        width: 100%;
    }

    th {
        background-color: #dcdcdc;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.vertical-end {
    align-self: end;
}

.vertical-center {
    align-self: center;
}
