@use "../3-helpers/_constants.scss" as c;

/**
 * Font faces
 */
@font-face {
    font-family: Montserrat;
    src: url("../../assets/fonts/montserrat/montserrat.ttf");
}

@font-face {
    font-family: LarkeNeueThin;
    src: url("../../assets/fonts/larke-neue/larke-neue-thin.ttf");
}

@font-face {
    font-family: LarkeNeueRegular;
    src: url("../../assets/fonts/larke-neue/larke-neue-regular.ttf");
}

@font-face {
    font-family: LarkeNeueBold;
    src: url("../../assets/fonts/larke-neue/larke-neue-bold.ttf");
}

/**
 * Headings
 */
.heading-1 {
    font-family: Montserrat;
    font-weight: 700;
    font-size: calc(18px + 0.2rem);
    text-transform: uppercase;
    color: c.$black;
}

.heading-1-light {
    font-family: Montserrat;
    font-weight: 700;
    font-size: calc(18px + 0.2rem);
    text-transform: uppercase;
    color: c.$white;
}

.heading-2 {
    font-family: Montserrat;
    font-weight: 600;
    font-size: calc(13.5px + 0.2rem);
    text-transform: uppercase;
    color: c.$black;
}

.heading-2-light {
    font-family: Montserrat;
    font-weight: 600;
    font-size: calc(13.5px + 0.2rem);
    text-transform: uppercase;
    color: c.$white;
}

.heading-3 {
    font-family: Montserrat;
    font-weight: 600;
    font-size: calc(11.2px + 0.2rem);
    text-transform: uppercase;
    color: c.$black;
}

.heading-3-light {
    font-family: Montserrat;
    font-weight: 600;
    font-size: calc(11.2px + 0.2rem);
    text-transform: uppercase;
    color: c.$white;
}

.heading-4 {
    font-family: LarkeNeueBold;
    font-size: calc(13.5px + 0.2rem);
    text-transform: capitalize;
    color: c.$black;
}

.heading-4-light {
    font-family: LarkeNeueBold;
    font-size: calc(13.5px + 0.2rem);
    text-transform: capitalize;
    color: c.$white;
}

.heading-5 {
    font-family: LarkeNeueBold;
    font-size: calc(10px + 0.2rem);
    text-transform: capitalize;
    color: c.$black;
}

.heading-5-light {
    font-family: LarkeNeueBold;
    font-size: calc(10px + 0.2rem);
    text-transform: capitalize;
    color: c.$white;
}

/**
 * Paragraphs
 */
.paragraph {
    font-family: LarkeNeueRegular;
    font-size: calc(10px + 0.2rem);
    color: c.$black;

    &.bold {
        font-family: LarkeNeueBold;
    }

    &.small {
        font-size: calc(8px + 0.2rem);
    }

    &.large {
        font-size: calc(12px + 0.2rem);
    }

    &.light {
        color: c.$white;
    }
}

/**
 * Table fonts
 */
.table-font {
    font-family: LarkeNeueBold;
    font-size: calc(11.7px + 0.2rem);
    background-color: c.$grey;
    color: c.$charcoal;
}

.table-font-light {
    font-family: LarkeNeueRegular;
    font-size: calc(11.7px + 0.2rem);
    color: c.$cloud;
}

/**
 * Miscellaneous
 */
.numbers {
    font-family: LarkeNeueBold;
    font-size: calc(65px + 0.2rem);
    color: c.$coral;
    line-height: calc(84px + 0.2rem);
}

.numbers-right-border {
    border-right: 1px solid c.$charcoal;
    padding-right: 1.5vw;
}

.numbers-right-border:last-child {
    border: none;
}

@media (max-width: 991px) {
    .numbers-right-border:last-child {
        border-bottom: 1px solid c.$charcoal;
    }
}

.numbers-right-border-coral {
    border-right: 2px solid c.$coral;
    padding-right: 1.5vw;
}

.col-3:last-child .numbers-right-border-coral {
    border: none;
}

.small-numbers {
    font-family: LarkeNeueBold;
    font-size: calc(37px + 0.2rem);
    color: c.$coral;
}

.montserrat-bullets {
    list-style-type: square;
}

.coral-underline {
    text-decoration: underline c.$coral 2px;
}

.uppercase {
    text-transform: uppercase !important;
}

.riskscape-data {
    font-family: LarkeNeueBold;
    color: c.$blue;
}

.matrix-data {
    font-family: LarkeNeueBold;
    color: c.$coral;
}

.coral-text {
    color: c.$coral;
}

@media (max-width: 991px) {
    .numbers-right-border {
        border-right: none;
    }
}
