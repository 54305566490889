@use "../3-helpers/constants" as c;

.nav-item {
    margin: auto;
    line-height: normal;
}

.nav-link.active {
    color: #ffffff;

    &::before {
        display: block;
        content: "";
        border-bottom: solid 3px c.$coral;
        transform: scaleX(1);
    }
}

.nav-link {
    &::before {
        display: block;
        content: "";
        border-bottom: solid 3px c.$coral;
        transform: scaleX(0);
        transition: transform 0s;
        transform-origin: 0% 60%;
    }

    &:hover::before,
    &:focus::before {
        transform: scaleX(1);
        transition: transform 0.35s ease-in-out;
    }

    &:hover,
    &:focus {
        color: #ffffff;
        cursor: pointer;
    }
}

.main-navbar {
    display: flex;
}

.nav-container {
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.nav-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .menu-toggle {
        display: none;
    }

    @media (max-width: 1340px) {
        .menu-toggle {
            display: block;
        }

        .nav-item {
            display: none;

            &.hide {
                display: none;
            }

            &:not(.hide) {
                display: block;
            }
        }
    }
}

@media (max-width: 1340px) {
    .nav-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.main-navbar-colors {
    background-color: c.$charcoal;
}

.main-menu-light {
    color: #ffffff;
}

.company-title {
    padding-left: 50px;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.admin-nav-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .menu-toggle {
        display: none;
    }

    @media (max-width: 800px) {
        .menu-toggle {
            display: block;
        }

        .nav-item {
            display: none;

            &.hide {
                display: none;
            }

            &:not(.hide) {
                display: block;
            }
        }
    }
}

@media (max-width: 800px) {
    .company-title {
        display: none;
    }

    .admin-nav-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.admin-navbar {
    display: flex;
    justify-content: center;
}

.admin-navbar-colors {
    color: #000000;
    background-color: c.$cloud;
}

.admin-nav {
    color: c.$charcoal !important;
}
