.loader {
    border: 22px solid #f3f3f3;
    border-top: 22px solid #f2825e;
    border-radius: 50%;
    width: 5vh;
    height: 5vh;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
