@use "../3-helpers/constants" as c;

.here {
    display: inline-block;
    text-decoration: none;
    color: c.$black;
    line-height: 1;
}

.here-light {
    display: inline-block;
    text-decoration: none;
    color: c.$white;
}

.here-light::after,
.here::after {
    display: block;
    content: "";
    transform: scaleX(1);
    border-bottom: 3px solid c.$coral;
    transition: transform 300ms ease-out;
    transform: scaleX(1);
}

.here:hover,
.here-light:hover {
    cursor: pointer;
    color: c.$coral;
}

.here:hover::after,
.here-light:hover::after {
    transform: scaleX(0);
}

.here::after,
.here-light::after {
    transform-origin: 0% 50%;
}

/**
 * Buttons
 */
@mixin button-styling() {
    box-shadow: none;
    height: 5vh;
    border-radius: 0;
    text-transform: capitalize;
    min-width: 30px;
    background-color: transparent;
}

.button {
    @include button-styling();
    border: solid 1px c.$black;
    font-family: Montserrat;
    font-weight: 600;
    font-size: calc(11.2px + 0.2rem);
    padding: 0 20px;
    color: c.$black;
    text-transform: uppercase;
}

.small-button {
    font-size: calc(9px + 0.2rem);
    height: 4.5vh;
    min-width: 18vw;
}

.button-light {
    @include button-styling();
    border: solid 1px c.$white;
    font-family: Montserrat;
    font-weight: 600;
    font-size: calc(11.2px + 0.2rem);
    padding: 0 20px;
    color: c.$white;
}

.button:not([disabled]):hover,
.button-light:not([disabled]):hover {
    border: solid 1px c.$coral;
    background-color: c.$coral;
}

button:disabled,
button[disabled] {
    color: c.$cloud;
}

/**
 * Sidenav buttons
 */
.sidenav-button {
    background-color: c.$charcoal;
    border: none;
    border-radius: 11%;
    width: 15vh;
    height: 2.8vw;
    color: c.$cloud;
    position: absolute;

    &.active {
        color: c.$coral;
        text-decoration: underline 3px c.$coral;
        text-underline-offset: 8px;
    }

    &:nth-child(2) {
        position: inherit;
        right: 1vh;
    }

    &:nth-child(3) {
        position: inherit;
        right: 17vh;
    }

    &:nth-child(4) {
        position: inherit;
        right: 33vh;
    }

    &:nth-child(5) {
        position: inherit;
        right: 49vh;
    }
}

.rotate-buttons {
    position: fixed;
    top: 32vh;
    transform: rotate(-0.25turn);
    right: 57vw;
}
