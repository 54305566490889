@use "../3-helpers/constants" as c;

.admin-top-grey-block-content {
    position: relative;
    display: grid;
}

.tab {
    overflow: hidden;
    border-bottom: 1px solid c.$charcoal;
    height: 40px;
}

.tab-links {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
    color: c.$charcoal;
}

button.active {
    color: c.$coral;
}

.key-circle {
    height: 25px;
    width: 25px;
    border: 1px solid c.$cloud;
    border-radius: 50%;
}

.in-progress-key {
    background-color: c.$cloud;
}

.delayed-key {
    border-color: c.$burnt-red;
}

.done-key {
    text-align: center;
    background-color: c.$cloud;
    font-family: arial;
    color: c.$coral;
    font-size: 18px;
    font-weight: bold;
    &::before {
        content: "✔";
    }
}

.cloud-bottom-border {
    border-bottom: 1px solid c.$cloud;
}

.bold {
    font-weight: bold;
}
