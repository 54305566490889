@use "../css/3-helpers/constants" as c;

input[type="checkbox"] {
	accent-color: c.$grey;
}

body {
	overflow-x: hidden !important;
}

input[required],
select[required],
textarea[required] {
	border-left: 3px solid c.$burnt-red;
}

::placeholder {
	color: c.$cloud;
	opacity: 1;
}

:-ms-input-placeholder {
	color: c.$cloud;
}

::-ms-input-placeholder {
	color: c.$cloud;
}

/**
 * Scrollbar Styles
 */

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background-clip: padding-box;
	border-radius: 10px;
	background: c.$cloud;
}

::-webkit-scrollbar-thumb:hover {
	background: #eee;
	cursor: grab;
}

::-webkit-scrollbar-thumb:active {
	cursor: grabbing;
}

/**
 * Button Styles
 */

.button-proceed {
	padding: 0 2vw;
	border: 1px solid #000;
	border-radius: 0;
	font-weight: 600 !important;
	font-family: Montserrat !important;
	text-transform: uppercase;

	&:hover {
		background-color: c.$coral;
		border: 1px solid c.$coral;
		color: #fff;
	}
}

.cancel-button {
	color: c.$white !important;
	background-color: c.$cloud !important;
}

.confirm-button {
	color: c.$white !important;
	background-color: c.$green !important;

	&:disabled {
		color: c.$white !important;
		background-color: c.$grey !important;
	}
}

/**
 * Dialog Styles
 */

.custom-dialog-container .mat-dialog-container {
	border-radius: 10px;
}

/**
 * Other Styles
 */

.center-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.burnt-red {
	color: c.$burnt-red;
}

.rs-geocode-dropdown {
	z-index: 100000 !important;
}

.hidden {
	display: none !important;
}


.control-disabled {
	pointer-events: none;
	opacity: 0.5;
}

.control-disabled input,
.control-disabled button {
	pointer-events: none;
	opacity: 0.5;
}