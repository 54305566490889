@use "../3-helpers/constants" as c;

/* Hide the browser's default checkbox */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    line-height: unset;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid black;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: c.$cloud;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: c.$cloud;
    border: unset;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 6px;
    top: 1px;
    width: 8px;
    height: 16px;
    border: solid c.$charcoal;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
