/**
 * Brand Colors
 */
$black: #000000;
$blue: #287baf;
$burnt-red: #dd3e2d;
$charcoal: #404040;
$cloud: #dbdbdb;
$coral: #f2825e;
$green: #abc18b;
$grey: #808080;
$red: #ff0000;
$white: #ffffff;
